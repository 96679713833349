@media only screen and (max-width: 600px) {
  /* Font Imports */
  @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Young+Serif&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Ojuju:wght@200..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

  /* Style */
  html {
    font-family: "Playfair Display", Times, serif;
    --cursor-color: white;
  }

  body {
    overflow-x: hidden;
    background: linear-gradient(-45deg, #a89a87, #afcf7e, #99bdff, #87a890);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  nav {
    font-size: 2.1vw;
    background-color: #08153bab;
    color: white;
    padding: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1.6rem;
    border-radius: 10px;
    box-shadow: 0px 0px 16px 8px #08153bab;
  }

  h1 {
    color: rgb(255, 255, 255);
    font-family: "Playfair Display", Times, serif;
    font-weight: 100;
    font-size: 14vw;
    margin-bottom: 7rem;
    margin-top: 7rem;
  }

  p {
    color: rgb(255, 255, 255);
    font-family: "Inconsolata", Times, serif;
    font-size: 2vw;
    font-weight: 600;
  }

  b,
  a,
  button {
    font-family: "Inconsolata", Times, serif;
    font-weight: 600;
  }

  button {
    margin-left: 2vw;
    background-color: #34312e00;
    border-radius: 10px;
    color: white;
    font-size: 1.3vw;
    margin-left: 4vw;
  }

  .bordered-section {
    border-radius: 10px;
    padding: 1rem;
  }

  a[href^="#"] {
    color: white;
    text-decoration: none;
    padding-left: 1vw;
    margin-right: -1vw;
    text-align: right;
  }

  .headingtop {
    margin-bottom: 0;
  }

  .paragraphtop {
    margin: auto;
    width: 70%;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 70px;
    margin-top: 1rem;
    font-size: 3vw;
  }

  .home-section {
    margin-bottom: 4rem;
    text-align: center;
  }

  /* About Section */
  .about-section {
    margin-top: 2rem;
    margin-bottom: 2rem; /* Reduced margin-bottom */
    padding-bottom: 2rem; /* Reduced padding-bottom */
    background-color: #ecdeca;
  }

  .aboutmeword {
    font-style: bold;
    font-family: "Playfair Display", Times, serif;
    font-weight: 800;
    font-size: 14vw;
    /* padding-top: -4rem; */
    color: rgb(55, 88, 255);
  }

  .aboutmewordcontain {
    margin-top: 6rem;
    margin-left: 3rem;
    margin-bottom: -3rem;
  }

  .about-left-paragraph {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    color: rgb(18, 121, 255);
    font-size: 2.4vw;
  }

  .carousel-container {
    width: 8rem;
    height: 2rem; /* Halved the height */
    border-radius: 10px;
    padding-right: 1.4rem;
    padding-top: 6rem;
  }

  .carousel-track {
    transition: transform 0.5s ease-in-out;
  }

  .vertical-carousel {
    position: relative;
    width: 30%; /* Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
    padding-right:4rem;
    margin-top: 6rem;
    border-radius: 20px;
  }

  .carousel-track img {
    border-radius: 10px;
  }

  .skill-box {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
  }

  .skill-title {
    text-align: center;
    font-size: 6vw;
    color: white;
  }

  .borderstyle {
    color: #344864;
    border-radius: 10px;
    background-color: white;
    border: 3px solid white;
    box-shadow: 0 4px 10px #08153bab;
    padding: 0.2rem;
    margin: 0.3rem;
    font-size: 3vw;
  }

  .skill-box-line {
    padding: 0.4rem;
  }

  /* Gallery Styles */
  .gallery-container {
    padding-left: 0.2rem;
    padding-right: 3rem;
    text-align: center; /* Center the container */
  }

  .gallery-title {
    margin-bottom: 2rem;
    margin-top: -3rem;
    text-align: center; /* Center the title */
  }

  .boxlong {
    padding-top: 2%;
    margin: 1rem;
    background-color: #e2f3ff;
    border-radius: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0 4px 10px rgba(98, 98, 98, 1);
    padding: 0.2rem;
    position: relative;
    width: 41%;
  }

  .cropped-image {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: block;
  }

  .box {
    background-color: #e2f3ff;
    border-radius: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0 4px 10px rgba(98, 98, 98, 1);
    padding: 0.1rem;
    vertical-align: top;
    width: 16%;
  }

  .box:hover .caption,
  .boxlong:hover .caption {
    opacity: 1;
  }

  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2vw;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: normal;
  }

  .gallery-para {
    color: rgb(84, 84, 84);
    font-size: 1.8vw;
    padding-top: 1%;
    white-space: normal;
  }

  .emoji {
    font-size: 3vw;
  }

  /* Project Styles */
  .project {
    background-color: #3b445a;
    margin-top: 0; /* Remove space between about me and project section */
    margin-bottom: 11rem;
    padding-top: 2rem;
    padding-bottom: 14rem;
  }

  .project-style {
    /* overflow-x: scroll !important; */
    /* display: flex; */
    flex-direction: column; /* Vertically place each deck */
    /* align-items: center; */
  }

  .imageback {
    width: 100%;
  }
  /* .cropped-image {
    width: 30%;
  } */

  .deck {
    width: 30%;
  }

  .container {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .project-box {
    margin-left: 4rem;
    padding-top: 1rem;
    margin-bottom: -4rem;
  }

  .astrowave {
    /* width: fit-content;
    display: flex;
    flex-direction: row;
    margin-right: 2rem; */
  }

  .project-title {
    font-family: "Roboto", Times, serif;
    transform: scaleY(1.4);
    font-size: 12vw; /* Adjusted to fit in the screen */
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 6rem;
  }

  .projectname {
    margin: 1rem;
    font-size: 6vw;
    margin-left: 2rem;
    font-style: oblique;
  }

  .projecttext {
    font-size: 4vw;
  }

  .projecttextfix {
    margin-left: -1.4rem;
  }

  



  .boxproject {
    background-color: rgb(30, 30, 30);
    border-radius: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0 4px 10px rgb(0, 0, 0);
    padding: 0.6rem;
    margin-left: 3rem;
    display: inline-block;
    vertical-align: top;
    width: 58vw;
  }

  .cropped-image.astrowave {
    /* padding-left: 1rem; */
    width: 20vw;
    height: 20vw;
  }

  .logo {
    font-size: 8vw;
    margin-bottom: -1rem;
    margin-top: -1rem;
  }

  a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    margin: 1rem;
  }

  .scrolltext {
    margin-top: 2rem;
    margin-left: 4rem;
    padding: 8px;
    width: 16%;
    border: 2px solid #ffffff;
    border-radius: 10px;
    text-align: center;
    font-size: 3vw;
  }

  .divider-symbol {
    font-size: 10vw;
    margin-bottom: -2rem;
    margin-top: -2rem;
    text-align: center;
  }

  /* Contact Section */
  .contact-section {
    padding-top: 4rem;
  }

  .contact-style {
    text-align: center;
    color: #eee;
    font-size: 6vw;
  }

  .centered-word {
    text-align: center;
    color: #eee;
    line-height: 6rem;
    font-size: 9vw;
    text-decoration: none;
    font-family: "Playfair Display", Times, serif;
    font-weight: 10;
  }

  .centered-word:hover {
    color: rgb(123, 123, 123);
  }

  /* Footer Style */
  footer {
    padding: 0.1rem;
    margin-top: 4rem;
    color: white;
    text-align: left;
  }

  @media (max-width: 390px) {
    .nav {
      display: block;
    }
  }
}